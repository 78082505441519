import _extends from "@babel/runtime/helpers/extends";
import { useBlocker, useMainStylesheetForContext, useBlockerClassName, useA11yId, BlockerHeroDialog, useBlockerMissingServices } from "../..";
import { useEffect } from "react";
import { useStylesheetProvider } from "../../contexts";
import { HTML_ATTRIBUTE_CONFIRM } from "@devowl-wp/headless-content-unblocker";
import { findUsedGcmConsentTypes } from "@devowl-wp/cookie-consent-web-client";
import { yieldLazyLoad } from "@devowl-wp/react-utils";
const BlockerTextContainer = yieldLazyLoad(import( /* webpackMode: "eager" */"./textContainer").then(_ref => {
  let {
    BlockerTextContainer
  } = _ref;
  return BlockerTextContainer;
}));
const BlockerHeroContainer = yieldLazyLoad(import( /* webpackMode: "eager" */"./heroContainer").then(_ref2 => {
  let {
    BlockerHeroContainer
  } = _ref2;
  return BlockerHeroContainer;
}));
const Blocker = _ref3 => {
  let {
    skipInitialConsent
  } = _ref3;
  const stylesheet = useMainStylesheetForContext();
  const {
    description: id
  } = stylesheet.reactRootSymbol;
  const {
    recorder,
    isGcm,
    blocker: {
      visualType,
      visualThumbnail
    },
    blockedNode,
    updateGcmConsentTypeChecked
  } = useBlocker();
  const [StylesheetProvider, stylesheetContextValue] = useStylesheetProvider(stylesheet);
  const useClassName = useBlockerClassName(stylesheet);
  const a11yExpanded = useA11yId(stylesheet);
  const missingServices = useBlockerMissingServices();

  // Prefill requested consent types
  useEffect(() => {
    if (process.env.PLUGIN_CTX === "pro" && isGcm && !skipInitialConsent) {
      /* onlypro:start */
      findUsedGcmConsentTypes(missingServices).forEach(type => updateGcmConsentTypeChecked(type, true));
      /* onlypro:end */
    }
  }, [isGcm, skipInitialConsent]);

  // Automatically start recorder if one exists
  useEffect(() => {
    if (recorder) {
      recorder.restart();
      return () => {
        recorder.toggle(false);
      };
    }
    return () => {
      // Silence is golden.
    };
  }, []);
  const attr = {
    id: a11yExpanded
  };
  const dialogAttr = {
    blockerClassName: useClassName,
    blockerOverlayId: `${id}-o`
  };
  return h(StylesheetProvider, {
    value: stylesheetContextValue
  }, blockedNode !== null && blockedNode !== void 0 && blockedNode.hasAttribute(HTML_ATTRIBUTE_CONFIRM) ? h(BlockerHeroDialog, _extends({}, dialogAttr, {
    heroContainerRef: {
      current: blockedNode
    }
  })) : h("div", {
    className: useClassName,
    id: id
  }, process.env.PLUGIN_CTX === "pro" && visualType === "hero" && visualThumbnail ? h(BlockerHeroContainer, _extends({}, dialogAttr, attr)) : h(BlockerTextContainer, attr)));
};
export { Blocker };